<template>
  <div class="px-4">
    <v-form class="mt-3">
      <v-card class="pa-4 mx-auto">
        <h2 class="secondary pa-3">COMMUNITY INFORMATION</h2>
        <div class="mt-3">
          <b class="mt-3">Ref No:</b>
          <v-select
            :items="cdps_com"
            @change="getCurrentRefData(dform.CommunityRefNo.id)"
            v-model.trim="dform.CommunityRefNo"
            item-text="CDPRef"
            item-value="CDPRef"
            return-object
            outlined
            dense
          ></v-select>
        </div>
        <div v-if="dform.CommunityRefNo">
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="3">
              <b>LGA:</b>
              <v-select
                :items="lgas"
                v-model.trim="dform.LGA"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <b>Community/Group Name:</b>
              <v-text-field
                v-model.trim="dform.CommunityName"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <b>Senatorial District:</b>
              <v-text-field
                v-model.trim="dform.SenatorialDist"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3">
              <b>Ward:</b>
              <v-text-field
                v-model.trim="dform.Ward"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3">
              <b>Population:</b>
              <v-text-field
                v-model.number="dform.Population"
                type="number"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3">
              <b>No of Male:</b>
              <v-text-field
                v-model.number="dform.CommunityMaleNo"
                type="number"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3">
              <b>No of Female:</b>
              <v-text-field
                v-model.number="dform.CommunityFemaleNo"
                type="number"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <b>Social Group Numbers:</b>          
            <v-row>
           

            <v-col cols="12" sm="6" md="4" lg="3">
              <b>No of Vulnerables:</b>
              <v-text-field
                v-model.number="dform.SocialGroupVulnerable"
                outlined
                dense
                type="number"
              ></v-text-field>
            </v-col>    
             <v-col cols="12" sm="6" md="4" lg="3">
              <b>Total No of Beneficiaries :</b>
              <v-text-field
                v-model.number="dform.noOfBeneficiaries"
                outlined
                dense
                type="number"
              ></v-text-field>
            </v-col>        
          </v-row>
          <b>Name of Beneficiaries:</b>
          <v-file-input
            placeholder="Upload file in excel format"
            accept=".xls, .xlsx"
            prepend-icon="mdi-file"
            v-if="!dform.Beneficiaries || edit.Beneficiaries"
            @change="handleBeneficiaries"
            outlined
          ></v-file-input>
          <div class="text-center" v-else>
            <b>Name of Beneficiaries </b>
            <v-icon size="4rem">mdi-file</v-icon>
            <v-btn
              color="primary"
              small
              shaped
              @click="edit.Beneficiaries = true"
              class="mr-1"
              >change file</v-btn
            >
          </div>
          <div class="text-center" v-if="edit.Beneficiaries">
            <v-btn
              color="primary"
              small
              shaped
              @click="edit.Beneficiaries = false"
              >cancel change file</v-btn
            >
          </div>

          <p v-if="id" class="text-end">
            <v-btn :loading="loading" @click="saveChanges" color="success"
              >Save Changes</v-btn
            >
          </p>
          <p v-else class="text-end">
            <v-btn :loading="loading" @click="post" color="success"
              >Submit</v-btn
            >
          </p>
        </div>
      </v-card>
    </v-form>
    <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p>{{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { snackMixin, editUserMixin } from "@/mixins";
import { mapState, mapGetters } from "vuex";
export default {
  name: "CommunityInfo",
  mixins: [snackMixin, editUserMixin],

  data() {
    return {
      id: false,
      cdps_com: [],
      dform: this.initializeForm(),
      edit: {
        Beneficiaries: false,
      },
    };
  },
  async created() {
    await apiClient.get("/cdps_info?u=2").then((res) => {
      if (Array.isArray(res.data)) {
        this.cdps_com = res.data;
      }
    });
    // .catch((err) => this.displayMsg(err.message, "error"));

    if (this.editUser) {
      this.id = true;
    }
  },
  computed: {
    ...mapState(["lgas"]),
    ...mapGetters(["getCurUser"]),
  },

  methods: {
    initializeForm(){
      return {
        cdps_id: "",
        CDPRef: "",
        CommunityRefNo: "",
        CommunityName: "",
        created_by: ""
      }
    },
    async getCurrentRefData(refNo) {
      await apiClient
        .get("/community_info?cdps_id=" + refNo)
        .then((res) => {
          if (Array.isArray(res.data)) {
            this.dform = res.data[0];
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((err) => this.displayMsg(err.message, "error"));
    },

    post() {
      this.loading = true;
      this.dform.type = "community";
      this.dform.modified_by = this.getCurUser;
      apiClient
        .put("/community_info", this.dform)
        .then((res) => {
          if (res.data.ok) {
            // this.id = res.data.id;
            this.loading = false;
            this.displayMsg("Community information submitted successfully");
            this.dform = this.initializeForm();
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((err) => {
          this.displayMsg(err.message, "error");
        });
    },

    handleBeneficiaries(file) {
      this.processFile(
        file,
        "Beneficiaries",
        "Beneficiary Lists saved successfully"
      );
    },

    processFile(file, filename) {
      if (!file) {
        return;
      }
      const validFile =
        file.name.includes(".xls") || file.name.includes(".xlsx");
      if (!validFile) {
        this.displayMsg(
          "Invalid file uploaded! Please upload excel file",
          "error"
        );
        return;
      }
      let fd = new FormData();
      let data = {
        id: this.id,
        filename: this.dform[filename],
      };
      fd.append("file", file);
      fd.append("data", JSON.stringify(data));
      let config = { header: { "Content-Type": "multipart/form-data" } };

      apiClient
        .post("/process_beneficiaries", fd, config)
        .then((res) => {
          if (res.data.filename) {
            this.dform[filename] = res.data.filename;
            this.edit.Beneficiaries = false;
            this.displayMsg("Excel file uploaded succesfully");
          } else {
            this.displayMsg(res.data, "error");
            this.loading = false;
          }
        })
        .catch((err) => {
          this.displayMsg(err.message, "error");
          this.loading = false;
        });
    },

    saveChanges() {
      this.dform.type = "community";
      this.dform.modified_by = this.getCurUser;
      this.loading = true;
      apiClient
        .put("/community_info", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.displayMsg("Changes saved successfully.Redirecting ...");
            this.loading = false;
            setTimeout(() => {
              this.$router.push({
                name: "CommunityInfoTable",
              });
            }, 2000);
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((err) => {
          this.displayMsg(err.message, "error");
          this.loading = false;
        });
    },
  },
};
</script>
